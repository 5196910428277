@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
html,
body {
  height: 100%;
  padding: 0;
  margin: 0;
  background: #f4f4f5 !important;
  color: #212121;
  font-family: -apple-system, system-ui, Roboto, "Helvetica Neue", "Segoe UI", Helvetica, Arial, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility; }

a {
  font-family: -apple-system, system-ui, Roboto, "Helvetica Neue", "Segoe UI", Helvetica, Arial, sans-serif !important; }

::-webkit-scrollbar {
  display: none !important; }

.ui.segment {
  box-shadow: none !important; }

.ui.segment {
  border: none;
  box-shadow: none; }

.ui.pagination.menu {
  margin: 0px 10px 20px 10px; }

.mobile_menu {
  display: none; }

.app {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden; }

.MainContainer {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  width: 100%;
  height: 100%; }
  .MainContainer .nav_wrapper {
    background: #2b2e32;
    width: 230px;
    min-width: 68px;
    max-width: 280px;
    height: 100%;
    position: relative;
    -webkit-flex-shrink: 0;
    font-size: 14px;
    color: #7f7f7f;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
    flex-shrink: 0; }
    .MainContainer .nav_wrapper .settings {
      padding: 10px;
      vertical-align: middle;
      background: #3c3f44;
      color: #fff;
      font-size: 15px;
      margin-bottom: 10px; }
      .MainContainer .nav_wrapper .settings .stg {
        display: inline; }
    .MainContainer .nav_wrapper .menu_1 {
      color: #fff;
      padding: 6px 10px; }
      .MainContainer .nav_wrapper .menu_1 .material-icons {
        font-size: 16px;
        margin-right: 8px;
        vertical-align: sub; }
      .MainContainer .nav_wrapper .menu_1:hover {
        background: rgba(255, 255, 255, 0.08); }
      .MainContainer .nav_wrapper .menu_1.active .title {
        font-weight: bold; }
  .MainContainer .main {
    height: 100%;
    width: 100%;
    overflow: hidden;
    overflow-y: auto;
    position: relative; }
    .MainContainer .main a {
      color: #0366d6 !important;
      text-decoration: none !important; }
    .MainContainer .main .full {
      padding: 10px;
      margin-top: 60px;
      z-index: 0; }
      .MainContainer .main .full .th_header {
        padding: 2px;
        width: 4px; }

.toolbar {
  background: #fff;
  font-size: 18px;
  padding: 20px;
  border-bottom: 1px solid rgba(34, 36, 38, 0.15);
  margin-bottom: -60px; }

.btn_toolbar {
  background: #fff;
  font-size: 18px;
  padding: 10px;
  border-bottom: 1px solid rgba(34, 36, 38, 0.15);
  position: fixed;
  width: 100%;
  z-index: 1; }
  .btn_toolbar .bug_id {
    margin: 0 20px 0 10px; }
  .btn_toolbar .btn_span {
    color: #404040 !important; }
  .btn_toolbar .orange {
    background-color: #fbca04 !important;
    color: #fbca04; }
  .btn_toolbar .new_window {
    background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAYAAABWdVznAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAOxAAADsQBlSsOGwAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAABmSURBVCiRxc4xDsJQDAPQB4LzlK0D0584QU/US3UB5o7ch4EFpCiKfqcKT05iO2ZvHAJ/Bt4wYsY57BxTQPsdsOKeP2RDxIQbFrwrQax0weNrguuWAYZK1Kv0qsJ6hhKnNOdaf8AHrN8L4Pm8SskAAAAASUVORK5CYII=") no-repeat left center;
    padding: 5px 0;
    width: 10px;
    height: 10px;
    display: inline-block;
    margin-left: 8px; }

.bug_rep_ass .label {
  color: rgba(0, 0, 0, 0.6);
  font-size: 1em; }

.bug_rep_ass .name {
  font-size: 1em;
  color: rgba(0, 0, 0, 0.87);
  font-weight: 700; }

.stuff_list_link {
  word-wrap: break-word;
  /* All browsers since IE 5.5+ */
  overflow-wrap: break-word;
  /* Renamed property in CSS3 draft spec */
  width: 100%; }

.bug_cell_status {
  background-color: #ee0701;
  padding: 2px !important;
  width: 6px !important;
  border-bottom: 1px solid #fff !important; }

.channel_sprt {
  border-top: 1px solid #525252;
  margin: 20px 10px -5px; }

@media only screen and (max-width: 767px) {
  .ui.table thead {
    display: none !important; }
  .ui.table tr {
    padding-top: 0 !important; }
  .stg .mobile_menu {
    display: inline; }
  .stg .desktop_menu {
    display: none; }
  .stg .icon {
    display: none; }
  .nav_wrapper {
    min-width: 50px !important;
    width: 50px !important; }
  .menu_1 {
    padding: 10px !important;
    text-align: center; }
    .menu_1 span {
      display: none; }
  .bug_id, .web_button {
    display: none !important; } }
